import React, {useEffect, useState} from "react";
import ImageGallery from 'react-image-gallery';
import "./gallery.css";
import "./product.css";
import {IoCartOutline, IoDiamondOutline, IoSparklesOutline, IoCheckmarkOutline} from "react-icons/io5";
import {useDispatch} from "react-redux";
import {FiMinus, FiPlus} from "react-icons/fi";
import {addCartAction} from "../../store/cartReducer";
import { useParams, useNavigate } from 'react-router-dom';
import ContentLoader from "react-content-loader";
import { Store } from 'react-notifications-component';

export default function Product(props) {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [clicked, setClicked] = useState(false);
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        setProduct(null);
        setImages([]);
        const fetchData = async () => {
            await fetch(process.env.REACT_APP_BASE_URL + 'api/product/' + params.productId + '/')
                .then((res) => {
                    if (res.status === 500) {
                        navigate('/not-found');
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    setProduct(data);
                    setImages(data.images);
                    if (data.fetched_in){
                        Store.addNotification({
                            title: "Чудово!",
                            message: ```Дані взяті з аллегро за ${data.fetched_in}
                             Оброблено за ${data.executed_in}```,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 10000,
                                onScreen: true
                            }
                        });
                    }
                })
                .catch((err) => {
                    //navigate('/not-found');
                    //console.log(err);
                });
        }
        fetchData()
    }, []);
    const addProductToCart = (image, product_code, title, price, id, quantity) => {
        setClicked(true);
        const total = price * quantity;
        dispatch(addCartAction({image: image, name: title, product_code: product_code,  count: quantity, price: total}));
        setTimeout(() => setClicked(false), 1000);
    }
    const [quantity, setQuantity] = useState(1)
    const [addActive, setAddActive] = useState(true)
    const [removeActive, setRemoveActive] = useState(false)
    const handleIncrement = () => {
        if (quantity < 20) {
            setQuantity(quantity + 1)
            setRemoveActive(true)
            if (quantity === 19) {
                setAddActive(false)
            }
        }
    }
    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
            setAddActive(true)
            if (quantity === 2){
                setRemoveActive(false)
            }
        }
    }
    return (
        <div className="product">

            <div className="container">
                {product ?
                    <>
                    <div className="product-left">
                        <ImageGallery items={product && images && images} showPlayButton={false} autoPlay={false}/>
                    </div>
                    <div className="product-right">
                        <h1>{product.title}</h1>
                        <div className="product-status">
                            <div className="condition">
                                {product.condition === "Nowy" ?
                                    <span className="new pill"><IoSparklesOutline/> Новий</span> :
                                    <span className="used pill"><IoCheckmarkOutline/> Б/У</span>
                                }
                            </div>
                            <div className="manufacturer">
                                {product && product.quality && product.quality.startsWith('O') ?
                                <span className="original pill"><IoDiamondOutline/> Оригінал</span>
                                    :
                                <span className="copy pill"><IoCheckmarkOutline/> Замінник</span>
                                }
                            </div>
                        </div>
                        <div className="price">
                            <span className="delivery pill">Вартість товару в м.Львів:</span>
                            <span className="item-price">
                            {product.price}<span>грн</span>
                            </span>
                        </div>
                        <div className="product-footer">
                            <div className="quantity">
                                <button className={removeActive ? "quan-remove active": "quan-remove"} onClick={handleDecrement}><FiMinus/></button>
                                <div className="quan-total">{quantity}</div>
                                <button className={addActive ? "quan-add active": "quan-add"} onClick={handleIncrement}><FiPlus/></button>
                            </div>
                            <button disabled={clicked} onClick={() => {addProductToCart(images[0].thumbnail, product.allegro_id, product.title, product.price, product.id, quantity)}} className={"button order" + (clicked ? " clicked": "")}>
                                {!clicked ? <IoCartOutline/>:<IoCheckmarkOutline/>}
                                {!clicked ? "Купити зараз": "Додано"}
                            </button>
                        </div>
                    </div>
                </>
                    :
                <>
                    <div className="product-left">
                        <ContentLoader
                            viewBox="0 0 500 500"
                            height="100%"
                            width="70%"
                            backgroundColor="#c6cfd8"
                            foregroundColor="#dee3e7"
                            {...props}
                        >
                            <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
                            <ellipse cx="120" cy="140" rx="28" ry="28" />
                        </ContentLoader>
                    </div>
                    <div className="product-right">
                        <ContentLoader width="100%" viewBox="0 0 500 500"  backgroundColor="#dee3e7"
                                       foregroundColor="#fff">
                            <rect x="15" y="15" rx="4" ry="4" width="90%" height="25" />
                            <rect x="15" y="50" rx="2" ry="2" width="40" height="25" />
                            <rect x="15" y="95" rx="3" ry="3" width="105" height="20" />
                            <rect x="125" y="95" rx="3" ry="3" width="105" height="20" />
                            <rect x="15" y="125" rx="3" ry="3" width="140" height="15" />
                            <rect x="15" y="145" rx="16" ry="16" width="85" height="42" />
                            <rect x="115" y="200" rx="2" ry="2" width="140" height="38" />
                            <rect x="15" y="210" rx="20" ry="20" width="20" height="20" />
                            <rect x="43" y="212" rx="2" ry="2" width="15" height="15" />
                            <rect x="65" y="210" rx="20" ry="20" width="20" height="20" />
                        </ContentLoader>
                    </div>

                </>
                }
            </div>
        </div>
    );
}

