import React, {useState} from "react";
import './styles.css'
import {IoCloseOutline, IoTrashBinOutline} from "react-icons/io5";
import {FiMinus, FiPlus} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {addCartAction, removeCartAction} from "../../../store/cartReducer";
import {NavLink} from "react-router-dom";
export default function Popup(props) {
    return (
        <div className="popup-overlay">
            <div className="overlay-bg" onClick={props.close}></div>
            <div className="popup">
                <div className="popup-header">
                    {props.title}
                    <div className="popup-close" onClick={props.close}><IoCloseOutline/></div>
                </div>
                <div className="popup-body">{props.type === "cart" ? <PopupCart close={props.close}/> : props.content}</div>
                <div className="popup-footer"></div>
            </div>
        </div>
    );
}
function PopupCart(props) {
    const cart = useSelector(state => state.cartReducer.cart);
    const total = cart.map(item => item.price * item.count).reduce((prev, next) => prev + next, 0);
    const count = cart.map(item => item.count).reduce((prev, next) => prev + next, 0);
    return (
        <div className="popup-cart">
        <div className="products-list">
            {cart.length > 0 ? cart.map(function(item, key) {
                        return (
                            <Product key={key} item={item}/>
                        )
            }
            )
                :
                <span>Ваш кошик пустий</span>
            }
        </div>
            {cart.length > 0 &&
                <div className="cart-footer">
                <div className="left-items">Всього {count} товарів </div>

                <div className="right-items">
                    <div className="cart-total"> Разом: <div className="total">{total}</div>
                    </div>
                    <NavLink to='/checkout' onClick={props.close}><button className="button">Оформити</button></NavLink>
                </div>
            </div>
            }
        </div>
    )
}
function Product(props){
    const dispatch = useDispatch();
    const removeProductFromCart = (product_code, quantity) => {
        dispatch(removeCartAction( {product_code: product_code, count: quantity}));
    }
    const addProductToCart = (product_code, quantity) => {
        dispatch(addCartAction( {product_code: product_code, count: quantity}));
    }
    const [quantity, setQuantity] = useState(props.item.count);
    const addActive = 20 > quantity > 0 && true;
    const removeActive = quantity > 1 && true;
    const handleIncrement = () => {
        if (quantity < 20) {
            setQuantity(quantity + 1)
            addProductToCart(props.item.product_code, 1)
        }
    }
    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
            removeProductFromCart(props.item.product_code, 1)
        }
    }
    return(
    <div className="cart-popup-product">
        <img src={props.item.image} alt="title"/>
        <div className="title">{props.item.name}</div>
        <div className="prod-code"><div className="product-code">Код товару: {props.item.product_code}</div></div>
        <div className="price">{props.price}</div>
        <div className="quantity">
            <button className={removeActive ? "quan-remove active" : "quan-remove"}
                    onClick={handleDecrement}><FiMinus/></button>
            <div className="quan-total">{quantity}</div>
            <button className={addActive ? "quan-add active" : "quan-add"} onClick={handleIncrement}>
                <FiPlus/></button>
        </div>
        <div className="delete" >
            <IoTrashBinOutline onClick={() => {removeProductFromCart(props.item.product_code, 'all')}}/>
        </div>
    </div>
    )
}
