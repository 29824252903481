import React from "react";
export default class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    render() {
        return (
            <div className='container'>
                <h1>Контакти</h1>
            </div>
        );
    }
}

