import React from "react";
import Menu from './menu';
import './styles.css';
import Logo from './euparts-logo.svg';
import Search from "./search";
import Cart from "../cart";
import {FiUser, FiMapPin, FiPhone} from "react-icons/fi";
import {NavLink} from "react-router-dom";
export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    render() {
        return (
            <div className="header-wrapper">
                <div className="header-top">
                    <div className='container'>
                        <div className='left-items'>
                            {/*<span><FiPhone/>+38(099)270-22-70</span>*/}
                            <span><FiPhone/>+38(099)270-22-70</span>
                        </div>
                        <div className='central-items'>
                            <NavLink to="/"><img src={Logo} className="header-logo" alt="Logo"/></NavLink>
                        </div>
                        <div className='right-items'>
                            {/*<span><FiUser/> Увійти</span>*/}
                            <NavLink to="/tracking"><span><FiMapPin/> Трекінг</span></NavLink>
                        </div>
                    </div>
                </div>
                <div className='header'>
                    <div className='container'>
                        <Search/>
                        <Cart/>
                    </div>
                </div>
                <div className="header-bottom">
                    <Menu/>
                </div>
            </div>
        );
    }
}

