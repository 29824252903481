import React from "react";
import './styles.css';
import {NavLink} from "react-router-dom";
export default class Notfound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    render() {
        return (
            <div className="notfound">
                <h3>От халепа!</h3>
                <p>Нажаль сторінки, або товару які Ви шукаєте немає на сайті.</p>
                <NavLink to="/"><button className="button">На головну</button></NavLink>
            </div>
        );
    }
}

