import React from "react";
import './styles.css';
import Logo from "./logo-dark.svg";
import Visa from "./visa.svg";
import Mastercard from "./mc.svg";
import {NavLink} from "react-router-dom";
export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    render() {
        return (
            <div className='footer'>
                <div className='container'>
                    <div className="left-items">
                        <NavLink to="/"><img src={Logo} className="footer-logo" alt="Logo"/></NavLink>
                        <span>EuParts - запчастини з Європи</span>
                    </div>
                    <div className="central-items">
                        <img src={Mastercard} alt='Mastercard'/>
                        <img src={Visa} alt='Visa'/>
                    </div>
                    <div className="right-items">
                        <span>Політика конфіденційності</span>
                        <span>Публічна оферта</span>
                    </div>
                </div>
                <div className='container'>
                <div className="footer-bottom">
                    <span>
                        Copyright EuParts &copy; {(new Date().getFullYear())}
                    </span>
                </div>
                </div>
            </div>
        );
    }
}

