import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import { Home } from './components/homepage'
import Notfound from './components/404'
import Header from './components/header'
import Footer from './components/footer'
import Contacts from './components/contacts'
import './App.css';
import React from "react";
import Category from "./components/category ";
import Product from "./components/product";
import SearchResults from "./components/search_results";
import ScrollToTop from "./scrollTop";

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Tracking from "./components/tracking";
import Checkout from "./components/checkout";
import Thanks from "./components/thanks";

function App() {
  return (
    <Router>
        <ReactNotifications />
        <Header/>
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/catalog/:categoryId" element={<Category />}/>
          <Route path="/product/:productId" element={<Product />}/>
          <Route path="/contacts" element={<Contacts />}/>
          <Route path="/checkout" element={<Checkout />}/>
          <Route path="/search/:queryString" element={<SearchResults />}/>
          <Route path="/search" element={<SearchResults />}/>
          <Route path="/tracking" element={<Tracking />}/>
          <Route path="/thanks" element={<Thanks />}/>
          <Route path='*' element={<Notfound />}/>
        </Routes>
        <Footer/>
    </Router>
  );
}

export default App;
