import React from "react";
import { FiSearch } from 'react-icons/fi';
import {useNavigate} from "react-router-dom";
export default function Search(props){
    const navigate = useNavigate();
    const handleSearch = e => {
        e.preventDefault();
        const value = e.target.searchfield.value;
        if (!isNaN(parseFloat(value)) && isFinite(value)) {
            navigate('/product/' + value + '/')
        } else {
            navigate('/search/?string=' + value )
        }
    }
    return (
        <form className="searchbox" onSubmit={e => handleSearch(e)}>
            <input type="text" id="searchfield" placeholder='Код товару / назва або номер запчастини'/>
            <button className="button" type="submit" ><FiSearch/>Пошук</button>
        </form>
    );
}

