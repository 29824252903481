import React, {useState} from "react";
import './styles.css'
import {IoCartOutline, IoCheckmarkOutline} from "react-icons/io5";
import {useDispatch} from "react-redux";
import {addCartAction} from "../../store/cartReducer";
import {NavLink} from "react-router-dom";
export default function Product(props){
    const [clicked, setClicked] = useState();
    const dispatch = useDispatch();
    const addProductToCart = (image, name, product_code, price, quantity) => {
        setClicked(true);
        const total = price * quantity;
        dispatch(addCartAction( {image: image, product_code: product_code, name: name, count: quantity, price: total}));
        setTimeout(() => setClicked(false), 1000);
    }
    return (
        <div className="product-item">
            <NavLink className="imagelink" to={"/product/" + props.product_code}><img src={props.image} alt={props.name}/></NavLink>
            <div className="info">
                <NavLink to={"/product/" + props.product_code}><h3>{props.name.substring(0, 30) + "..."}</h3></NavLink>
                <span className="product-code">Код товару: {props.product_code}</span>
                <div className="product-footer">
                    <div className="product-price">
                        {props.price}
                    </div>
                    <button disabled={clicked} onClick={() => {addProductToCart(props.image, props.name, props.product_code, props.price, 1)}} className={"button order" + (clicked ? " clicked": "")}>
                        {!clicked ? <IoCartOutline/>:<IoCheckmarkOutline/>}
                        {!clicked ? "Додати": "Додано"}
                    </button>
                </div>
            </div>
        </div>
    );
}

