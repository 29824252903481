import {createStore, combineReducers} from "redux";
import {loadState, saveState} from "../sessionStorage";
import {cartReducer} from "./cartReducer";

const persistedState = loadState();
const rootReducer = combineReducers({
    cartReducer,
})
export const store = createStore(rootReducer, persistedState)

store.subscribe(() => {
    saveState(store.getState());
});
