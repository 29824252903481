import React, {useEffect, useState} from "react";
import Product from './Product';
import './styles.css'
import ProductFilter from "../homepage/product-filter";
import {Store} from "react-notifications-component";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ContentLoader from "react-content-loader";
export default function SearchResults() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [results, setResults] = useState(null);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        setResults(null);
        const data = {
            string: searchParams.get('string'),
            make: searchParams.get('make'),
            model: searchParams.get('model'),
            category: searchParams.get('category'),
            subCategory: searchParams.get('subCategory')
        }
        Object.keys(data).forEach(key => {
            if (data[key] === null || data[key] === undefined) {
                delete data[key];
            }
        });
        const fetchData = async () => {
            await fetch(process.env.REACT_APP_BASE_URL + 'api/search/?' + new URLSearchParams(data).toString())
                .then((res) => {
                    if (res.status === 500) {
                        navigate('/not-found');
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    setResults(data);
                })
                .catch((err) => {

                });
        }
        fetchData()
    }, [searchParams]);
    const [makes, setMakes] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + 'api/carmakes/')
            .then((res) => res.json())
            .then((data) => {
                setMakes(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <div className="category">
            <div className="container">
                <ProductFilter makes={makes}/>
                {results ?
                <div className="items">
                    {results && results.map((item, key) => <Product product_code={item.allegro_id} price={item.price} name={item.title} image={item.images.length > 0 ? item.images[0].original : "/#"}/>)}
                </div>
                    :
                    <ContentLoader width="100%" viewBox="0 0 500 500"  backgroundColor="#c6cfd8"
                                   foregroundColor="#dee3e7">
                        <rect x="3%" y="10" rx="4" ry="4" width="30%" height="175" />
                        <rect x="36%" y="10" rx="4" ry="4" width="30%" height="175" />
                        <rect x="69%" y="10" rx="4" ry="4" width="30%" height="175" />
                        <rect x="3%" y="200" rx="4" ry="4" width="30%" height="175" />
                        <rect x="36%" y="200" rx="4" ry="4" width="30%" height="175" />
                        <rect x="69%" y="200" rx="4" ry="4" width="30%" height="175" />
                    </ContentLoader>
                }
            </div>
        </div>
    );
}

