import React, { useEffect, useState } from "react";
import { FiShoppingCart } from 'react-icons/fi';
import {useSelector} from "react-redux";
import '../HOC/popup/styles.css'
import Popup from "../HOC/popup";
export default function Cart() {
    const [popupOpen, setPopupOpen] = useState();
    const openPopup = () => {setPopupOpen(!popupOpen)};
    useEffect(() => {
        popupOpen ? document.body.style.overflow = 'hidden': document.body.style.overflow = 'unset';
    }, [popupOpen ]);
    const cart = useSelector(state => state.cartReducer.cart);
    const total = cart.map(item => item.price * item.count).reduce((prev, next) => prev + next, 0);
    const itemsCount = cart.map(item => item.count).reduce((prev, next) => prev + next, 0);
    useEffect(() => {
        document.title = `You clicked ${total} times`;
    });
    return (
        <div className="cart-wrapper" key={itemsCount}>
            <div className="cart" onClick={openPopup}>
                <div className="cart-icon">
                    <FiShoppingCart/>
                    {itemsCount > 0 &&
                    <span>{itemsCount}</span>
                    }
                </div>
                <div className="amount">{total}</div>
            </div>
            {popupOpen && <Popup title="Кошик покупок" cart={cart} type="cart" close={openPopup}/>}
        </div>
    );
}

