import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addCartAction, removeCartAction} from "../../store/cartReducer";
import {FiMinus, FiPlus, FiSearch, FiShoppingCart} from "react-icons/fi";
import {IoTrashBinOutline} from "react-icons/io5";
import './styles.css'
import {Store} from "react-notifications-component";
import {useNavigate} from "react-router-dom";
export default function Checkout(){
    const navigate = useNavigate();
    const cart = useSelector(state => state.cartReducer.cart);
    const total = cart.map(item => item.price * item.count).reduce((prev, next) => prev + next, 0);
    const count = cart.map(item => item.count).reduce((prev, next) => prev + next, 0);
    const handleAddOrder = e => {
        e.preventDefault();
        const data = {
            first_name: e.target.firstname.value,
            last_name: e.target.lastname.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
            products: cart.map(function (item, key) {
                    return (
                        {product: item.product_code, quantity: item.count}
                    )
                }
            )
        }
        fetch(process.env.REACT_APP_BASE_URL + 'api/addOrder/', {method: "POST", headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }, body: JSON.stringify(data)})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                navigate('/thanks');
            })
            .catch((err) => {
                //navigate('/not-found');
                //console.log(err);
            })
    }
        return (
            <div className='container column'>
                <h1>Оформлення замовлення</h1>
                <div className="checkout_form">
                    <div className="checkout">
                        <form className="box" onSubmit={e => handleAddOrder(e)}>
                            <input type="text" id="firstname" placeholder="Ім'я"/>
                            <input type="text" id="lastname" placeholder="Прізвище"/>
                            <input type="text" id="email" placeholder="Email"/>
                            <input type="text" id="phone" placeholder="Номер телефону"/>
                            <button className="button" type="submit" ><FiShoppingCart/>Оформити</button>
                        </form>
                    </div>
                <div className="products-list">
                    {cart.length > 0 ? cart.map(function(item, key) {
                                return (
                                    <Product key={key} item={item}/>
                                )
                            }
                        )
                        :
                        <span>Ваш кошик пустий</span>
                    }
                </div>
                </div>
            </div>
        );
}
function Product(props){
    const dispatch = useDispatch();
    const removeProductFromCart = (product_code, quantity) => {
        dispatch(removeCartAction( {product_code: product_code, count: quantity}));
    }
    const addProductToCart = (product_code, quantity) => {
        dispatch(addCartAction( {product_code: product_code, count: quantity}));
    }
    const [quantity, setQuantity] = useState(props.item.count);
    const addActive = 20 > quantity > 0 && true;
    const removeActive = quantity > 1 && true;
    const handleIncrement = () => {
        if (quantity < 20) {
            setQuantity(quantity + 1)
            addProductToCart(props.item.product_code, 1)
        }
    }
    const handleDecrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
            removeProductFromCart(props.item.product_code, 1)
        }
    }
    return(
        <div className="checkout-product">
            <img src={props.item.image} alt="title"/>
            <div className="title">{props.item.name.substring(0, 20) + "..."}</div>
            <div className="prod-code"><div className="product-code">Код товару: {props.item.product_code}</div></div>
            <div className="price">{props.price}</div>
            <div className="quantity">
                <button className={removeActive ? "quan-remove active" : "quan-remove"}
                        onClick={handleDecrement}><FiMinus/></button>
                <div className="quan-total">{quantity}</div>
                <button className={addActive ? "quan-add active" : "quan-add"} onClick={handleIncrement}>
                    <FiPlus/></button>
            </div>
            <div className="delete" >
                <IoTrashBinOutline onClick={() => {removeProductFromCart(props.item.product_code, 'all')}}/>
            </div>
        </div>
    )
}


