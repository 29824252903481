import React, {useEffect, useState} from "react";
import './styles.css'
import {IoChevronBack} from "react-icons/io5";
import {useDispatch} from "react-redux";
import {addCartAction} from "../../store/cartReducer";
import {NavLink, useLocation} from "react-router-dom";
import Product from "../search_results/Product";
export default function CategoryList(props){
    const location = useLocation();
    return (
        <ul className="catlist">
            {
                props.categories && props.categories.maincat.allegro_id !== props.categories.maincat.parentcat.allegro_id && <NavLink to={(location.pathname + "?category=" + (props.categories && props.categories.maincat.parentcat.allegro_id))}><IoChevronBack/>&nbsp;&nbsp;{props.categories && props.categories.maincat.parentcat.title}</NavLink>
            }
            <h4>Категорія {props.categories && props.categories.maincat.title}</h4>

            {props.categories && props.categories.categories.map((item, key) => <NavLink to={(location.pathname + "?category=" + item.allegro_id )}><li key={key}>{item.title}</li></NavLink>)}
        </ul>
    );
}

