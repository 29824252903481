import React, {useEffect, useState} from "react";
import Slider from "./slider"
import { Makes } from "./makes"
import ProductFilter from "./product-filter"
import Slide1 from "./slide1.webp"
import './styles.css'


export const Home = () => {
    const [makes, setMakes] = useState([]);

    useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL + 'api/carmakes/')
    .then((res) => res.json())
    .then((data) => {
        setMakes(data);
    })
    .catch((err) => {
        console.log(err.message);
    });
    }, []);

    return (
            <div className="homepage">
                <div className="container">
                    <ProductFilter makes={makes}/>
                    <div className="homepage-slider">
                        <Slider loop={ false }
                                selected={ 0 }
                                showArrows={ true }
                                showNav={ false }>
                            <div><img src={Slide1} alt="Slide"/></div>
                            <div><img src={Slide1} alt="Slide"/></div>
                        </Slider>
                    </div>
                </div>
                <div className="container">
                    <Makes makes={makes}/>
                </div>
            </div>
        );
}

