import React from "react";
import './make-item.css'
import {NavLink} from "react-router-dom";
export default class Makeitem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    render() {
        const url = process.env.REACT_APP_BASE_URL + "statico/img/makes/" + this.props.make.title.toLowerCase().replace(/ /g," ") + ".png";
        return (
            <NavLink className="item" to={"/catalog/" + this.props.make.title.toLowerCase().replace(/ /g,"-")}>
                <img src={url} alt={this.props.make.title}/>
                {this.props.make.title}
            </NavLink>
        );
    }
}

