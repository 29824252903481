import React, {useEffect, useState} from "react";
import Product from './Product';
import './styles.css'
import ProductFilter from "../homepage/product-filter";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import ContentLoader from "react-content-loader";
import CategoryList from "./categoryList";
export default function Category() {
    const params = useParams();
    const [results, setResults] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const category = searchParams.get('category');
    const navigate = useNavigate();
    const [makes, setMakes] = useState([]);
    useEffect(() => {
        let url = 'api/make_listing/' + params.categoryId + '/';
        if (category){
            url = 'api/make_listing/' + params.categoryId + '/' + `?category=${category}`
        }
        const fetchData = async () => {
            await fetch(process.env.REACT_APP_BASE_URL + url)
                .then((res) => {
                    if (res.status === 500) {
                        navigate('/not-found');
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    setResults(data);
                })
                .catch((err) => {

                });
        }
        fetchData()
    }, [searchParams.get('category')]);
    useEffect(() => {
        fetch(process.env.REACT_APP_BASE_URL + 'api/carmakes/')
            .then((res) => res.json())
            .then((data) => {
                setMakes(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);
    const [categories, setCategories] = useState(null);

    useEffect(() => {
        let cat;
        if (category){
            cat = category;
        } else {
            cat = 620;
        }
        fetch(process.env.REACT_APP_BASE_URL + 'api/categories/' + cat + '/')
            .then((res) => res.json())
            .then((data) => {
                setCategories(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [searchParams.get('category')]);
    return (
        <div className="category">
            <div className="container">
                <div className="filters">
                <ProductFilter makes={makes}/>
                <CategoryList categories={categories}/>
                </div>
                {results ?
                    <div className="items">
                        {results ? results.map((item, key) => <Product product_code={item.allegro_id} price={item.price} name={item.title} image={item.images.length > 0 ? item.images[0].original : '/#'}/>)
                        :
                            <h4>None</h4>
                        }
                    </div>
                    :
                    <ContentLoader width="100%" viewBox="0 0 500 500"  backgroundColor="#c6cfd8"
                                   foregroundColor="#dee3e7">
                        <rect x="3%" y="10" rx="4" ry="4" width="30%" height="175" />
                        <rect x="36%" y="10" rx="4" ry="4" width="30%" height="175" />
                        <rect x="69%" y="10" rx="4" ry="4" width="30%" height="175" />
                        <rect x="3%" y="200" rx="4" ry="4" width="30%" height="175" />
                        <rect x="36%" y="200" rx="4" ry="4" width="30%" height="175" />
                        <rect x="69%" y="200" rx="4" ry="4" width="30%" height="175" />
                    </ContentLoader>
                }
            </div>
        </div>
    );
}

