import React, {useEffect, useState} from "react";
import './styles.css'
import {FiSearch} from "react-icons/fi";
import {useNavigate, useParams} from "react-router-dom";

export default function ProductFilter(props){
        const [make, setMake] = useState(null);
        const [model, setModel] = useState(null);
        const params = useParams();
        const [subCategory, setSubCategory] = useState(null);
        const [category, setCategory] = useState(null);
        const [modelList, setModelList] = useState([]);
        const [subcategoriesList, setSubcategoriesList] = useState([]);
        const [categoriesList, setCategoriesList] = useState([]);
        const [partType, setPartType] = useState(null);
        const [part, setPart] = useState(null);
        const navigate = useNavigate();
        const changeFilters = (event) => {
            const type = event.target.id;
            if (type === "makes") {
                setMake(event.target.value);
                setModel(null);
                setModelList([]);
                fetch(process.env.REACT_APP_BASE_URL + 'api/carmodels/' + event.target.value + '/')
                    .then((res) => res.json())
                    .then((data) => {
                        setModelList(data);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            } else if (type === "models") {
                setModel(event.target.value);
                fetch(process.env.REACT_APP_BASE_URL + 'api/categories/620/')
                    .then((res) => res.json())
                    .then((data) => {
                        setCategoriesList(data.categories);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            } else if (type === "category") {
                setCategory(event.target.value);
                fetch(process.env.REACT_APP_BASE_URL + 'api/categories/' + event.target.value + '/')
                    .then((res) => res.json())
                    .then((data) => {
                        setSubcategoriesList(data.categories);
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            } else if (type === "subcategory") {
                setSubCategory(event.target.value);
            }
        }
        const submitFilters = (event) => {
            const data =
                {
                    make: make,
                    model: model,
                    category: category,
                    subCategory: subCategory,
                }
                Object.keys(data).forEach(key => {
                    if (data[key] === null || data[key] === undefined) {
                        delete data[key];
                    }
                });
                if (params.productId) {
                    const isProduct = params.productId && `${params.productId}/`;
                    navigate(`/search/${isProduct}` + '?' + new URLSearchParams(data).toString())
                } else {
                    navigate(`/search/?` + new URLSearchParams(data).toString())
                }
        }
        return (
            <div className="product-filter">
                <h5>Фільтр автозапчастин</h5>
                <div className="filter-group">
                    <select onChange={changeFilters} value={make} className="select" id="makes">
                        <option value="null">Оберіть марку авто</option>
                        {props.makes && props.makes.map((item, key) => <option key={key} value={item.pk}>{item.title}</option>)}
                    </select>
                </div>
                <div className="filter-group select">
                    <select onChange={changeFilters} value={model} className="select" disabled={modelList.length > 0 ? "": "disabled"} id="models">
                        <option value="null">Оберіть модель авто</option>
                        {modelList && modelList.map((item, key) => <option key={key} value={item.pk}>{item.allegro_title}</option>)}
                    </select>
                </div>
                <div className="filter-group select">
                    <select onChange={changeFilters} value={category} className="select" disabled={categoriesList.length > 0 ? "": "disabled"} id="category">
                        <option>Оберіть тип запчастини</option>
                        {categoriesList && categoriesList.map((item, key) => <option value={item.allegro_id} key={key}>{item.title}</option>)}
                    </select>
                </div>
                <div className="filter-group">
                    <select onChange={changeFilters} value={subCategory} className="select" disabled={subcategoriesList.length > 0 ? "": "disabled"} id="subcategory">
                        <option>Оберіть запчастину</option>
                        {subcategoriesList && subcategoriesList.map((item, key) => <option key={key} value={item.allegro_id}>{item.title}</option>)}
                    </select>
                </div>
                <button className="button" onClick={submitFilters} type="button"><FiSearch/>Знайти запчастини</button>
            </div>
        );
}

