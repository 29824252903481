import React from "react";
import {
   NavLink
} from "react-router-dom";
export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    render() {
        return (
            <div className="container">
                <ul className="nav">
                    <li><NavLink to="/">Головна</NavLink> </li>
                    <li><NavLink to="/contacts">Контакти</NavLink> </li>
                </ul>
            </div>
        );
    }
}

