
const defaultState = {
    cart: [],
}
const ADD_CART = 'ADD_CART'
const REMOVE_CART = 'REMOVE_CART'
export const cartReducer = (state = defaultState, action) => {
    let element = action.payload && state.cart.find(item => item.product_code === action.payload.product_code);
    switch (action.type) {
        case ADD_CART:
            if(!element) return {...state, cart: [...state.cart, action.payload]};
            element.count = element.count + action.payload.count;
            return {...state, cart: [...state.cart]};
        case REMOVE_CART:
            if (action.payload.count !== 1 || element.count === 1) return {...state, cart:state.cart.filter(item => item.product_code !== action.payload.product_code)};
            element.count = element.count - action.payload.count;
            return {...state, cart: [...state.cart]};
        default:
            return state
    }
}
export const addCartAction = (payload) => ({type: ADD_CART, payload});
export const removeCartAction = (payload) => ({type: REMOVE_CART, payload});
