import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './tabs.css';
import Makeitem from './make-item'

export const Makes = (props) => {


    return (
            <div className="makes">
                <Tabs>
                    <TabList>
                        <Tab>Каталог автомобілів</Tab>
                        <Tab>мотоциклів</Tab>
                        <Tab>квадроциклів</Tab>
                    </TabList>

                    <TabPanel>
                        {props.makes && props.makes.map(item =>
                            <Makeitem make={item}/>
                        )}
                    </TabPanel>
                    <TabPanel>
                        {props.makes && props.makes.map(item =>
                            <Makeitem make={item}/>
                        )}
                    </TabPanel>
                    <TabPanel>
                        {props.makes && props.makes.map(item =>
                            <Makeitem make={item}/>
                        )}
                    </TabPanel>

                </Tabs>
            </div>
        );
}
