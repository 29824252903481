import React from "react";
export default class Tracking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }

    render() {
        return (
            <div className='container'>
                <h1>Tracking</h1>
            </div>
        );
    }
}

